import React, {useEffect, useState} from "react"
import { graphq, Link } from "gatsby"
import Layout from '../components/layout'
import view from "../components/blogPage/blog.module.scss";


const SingleCategory = (props) => {
	const {pageContext} = props;
	const tag = pageContext.tagName;
	const posts = pageContext.searchedPosts;
	const blogManagement = pageContext.blogmanagement;
	const allBlogPagesBottomHTML = blogManagement.allBlogPagesBottomHTML;
	const categoriesBlogResultsHTML = blogManagement.categoriesBlogResultsHTML;
	const blogResultsTopHTML = blogManagement.blogResultsTopHTML;
	const pageSeo = {seo:{
		MetaDesc: `All blog post results for ${pageContext.dealerName} related to ${tag}.`,
		MetaTitle: `${tag} Blog Posts | ${pageContext.dealerName}`,
		tags: []}
	}
	
	return(
		<Layout seo={pageSeo}>
			<div dangerouslySetInnerHTML={{ __html: blogResultsTopHTML }} />
			<div style={{ width: "95%", margin: '0 auto', maxWidth: 1100, padding: "102px 15px 1rem"}}>
				<h1 style={{textAlign:'center'}}>Showing posts in <span  className={`${view['tag-title']}`}>{tag}</span></h1>
				<ul className={`${view['tag-list']}`}>
					{posts.map(post => {
					
						const title = JSON.parse(post.pageTitle);
						const slug = JSON.parse(post.slug); 
						const content = JSON.parse(post.content);
						const id = JSON.parse(post._id);
						const parsedContent = content.blogposts;
						
						return(
							<li key={id}>
								<Link className={`${view['tag-link']}`} href={slug}>
								
									<img src={`${parsedContent.thumbnailImage}`} />
									<div style={{ padding:10 }}>
										<h2 style={{fontSize:18, marginTop:5}}>{title}</h2>
										<p style={{textAlign:'left', fontStyle:'italic'}}>Posted on: {parsedContent.publicationDate}</p>
									</div>
								</Link>
							</li>
						)
					})}
				</ul>
			</div>
			{/*BLOG RESULTS DATA */}
			<div dangerouslySetInnerHTML={{ __html: categoriesBlogResultsHTML }} />
			
			{/* ALL BLOG DATA */}
			<div dangerouslySetInnerHTML={{ __html: allBlogPagesBottomHTML }} />
		</Layout>
	)
}

export default SingleCategory;